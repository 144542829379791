<template lang="html">
  <div class="festivals">
    <transition-group @before-enter="beforeEnter" @enter="enter" mode="out-in">
      <div
        class="festivals__item"
        :key="festival.id"
        v-for="festival in getFestivals"
        @click="goToFestival(festival.id)"
      >
        <div class="festivals__item-image">
          <img
            v-if="festival['thumbnail_image']"
            :src="festival['thumbnail_image']['sizes']['medium_large']"
            :alt="festival['thumbnail_image']['alt']"
          />
          <div class="festivals__image-dummy" v-else>
            <DummyImage />
          </div>
        </div>
        <div class="festivals__item-heading">
          <Heading :heading="festival.title" />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Heading from '@/components/Heading';
import DummyImage from '@/components/DummyImage';

export default {
  name: 'Festivals',
  components: { DummyImage, Heading },
  data() {
    return {
      idx: 0,
    };
  },
  computed: {
    getFestivals() {
      return this.$store.state.cpt.festivals;
    },
  },
  created() {
    this.$store.dispatch('cpt/fetchFestivals');
  },
  beforeUnmount() {
    this.$store.commit('cpt/setFestivals', []);
  },
  methods: {
    goToFestival(id) {
      this.$store.commit('routing/setCurrentRoute', 'single-post');
      this.$store.commit('routing/setSinglePostData', {
        postType: 'festivals',
        postId: String(id),
      });
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transition = 'opacity 1s ease';
      el.style.transitionDelay = this.idx * 0.15 + 's';
      this.idx++;
    },
    enter(el) {
      el.style.opacity = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '~@/styles/variables';
.festivals {
  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    &-heading {
      margin-top: -25px;
    }
    &-image {
      position: relative;
      height: 0;
      padding-bottom: math.div(177, 315) * 100%;

      & > * {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
}
.image {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
  }
}
</style>
